
export const integrateCompanyArr = [
  { integrate_company_id: 1, integrate_company_name: 'Item 1', imageUrl: '', isIntegrate: false },
  { integrate_company_id: 2, integrate_company_name: 'Item 2', imageUrl:  '', isIntegrate: false },
  { integrate_company_id: 3, integrate_company_name: 'Item 3', imageUrl:  '', isIntegrate: false },
  { integrate_company_id: 4, integrate_company_name: 'Item 4', imageUrl: '', isIntegrate: false },
  { integrate_company_id: 5, integrate_company_name: 'Item 5', imageUrl:  '', isIntegrate: false },
  { integrate_company_id: 6, integrate_company_name: 'Item 6', imageUrl:  '', isIntegrate: false },
];

