import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { io, Socket } from "socket.io-client";

export interface ISocket_store_initial {
  socketSingleTon: any;
}
export const Socket_store_initial: ISocket_store_initial = {
  socketSingleTon: null, // io()
};

const initialState = Socket_store_initial;

export const socketSlice = createSlice({
  name: 'inboxSlice',
  initialState,
  reducers: {
    setSocketIO: (
        state: ISocket_store_initial,
        action: PayloadAction<any>
    ) => {
        state.socketSingleTon = action.payload;
    },
    setSocket: (
        state: ISocket_store_initial,
        action: PayloadAction<any>
    ) => {
        state.socketSingleTon = action.payload;
    },
  },
});

export const {
    setSocket,
    setSocketIO,
} = socketSlice.actions;

export const socketReducer = socketSlice.reducer;
