import { FC, useState, useEffect } from "react";

import { UploadStatusStyle as Styled } from "./uploadStatus.style";
import { useUploadViewState } from "screens/UploadingView/useUploadView.state";

interface IUploadStatus {
	totalUploadss?: number | null;
	totalSuccesss?: number | null;
	totalPendingg?: number | null;
	totalRejected?: number | null;
    margin?: string;
    uploadingModalToggle: () => void;
    onFetchReceiptsHandler: () => void;
    s3Status: {status: string, message: string, progress: number, job_id: string}[];
}
export const UploadStatus: FC<IUploadStatus> = (props) => {
	const { s3Status, onFetchReceiptsHandler, margin, uploadingModalToggle, totalUploadss=50, totalSuccesss=40, totalPendingg=9, totalRejected=1 } = props;
    const [uploadStatus, setUploadStatus] = useState(5);

    const [totalUploads, setTotalUpload] = useState(s3Status?.length);
    const [totalSuccess, setTotalSuccess] = useState(0);
    const [totalError, setTotalError] = useState(0);
    const [totalPending, setTotalPending] = useState(s3Status?.length);

	useEffect(() => {
        setTotalUpload(s3Status?.length);
        const successStatus: any[] = [];
        s3Status.forEach((stat) => stat.progress==100 ? successStatus.push(true) : null);
        setTotalSuccess(successStatus.length);
        const errorStatus: any[] = [];
        s3Status.forEach((stat) => stat.status=='error' ? errorStatus.push(true) : null);
        // s3Status.map((stat) => stat.status=='error');
        setTotalError(errorStatus.length);
        // console.log(successStatus);
        setTotalPending(totalUploads - (successStatus.length + errorStatus.length ));
	}, [s3Status, totalError]);

    useEffect(() => {
        if(totalPending == 0) {
            setTimeout(() => {
                onFetchReceiptsHandler();
            }, 4000);
        }
    }, [totalPending]);
    

	return (
        <Styled.Wrapper onClick={uploadingModalToggle} margin={margin} title={`Total(${totalUploads}) | Success(${totalSuccess}) | Pending(${totalPending}) | Rejected(${totalError})`}>
            <Styled.Title>Uploading... <Styled.Stats>Total: {totalUploads} ( <Styled.Stat color={"green"}>{totalSuccess}</Styled.Stat> | <Styled.Stat color={"mango"}>{totalPending}</Styled.Stat> | <Styled.Stat color={"red"}>{totalError}</Styled.Stat> )</Styled.Stats></Styled.Title>
            <Styled.Bar>
                <Styled.Progress width={(100 * totalSuccess) / totalUploads}></Styled.Progress>
                {/* <Styled.Progress width={((100*totalPending)/100)-100}></Styled.Progress> */}
            </Styled.Bar>
        </Styled.Wrapper>
    )
};
