export const receipts = [
  {
    id: 'receipt 1',
  },
  {
    id: 'receipt 2',
  },
  {
    id: 'receipt 3',
  },
];
