import { FC, memo, useState, useEffect } from "react";
import { Outlet } from "react-router-dom";

// import { EmptyData } from 'components/EmptyData';
import { LoaderComponent } from "components/Loader";
// import { ROUTES } from 'constants/routes';
import { theme } from "../../styles/theme";

import { useDispatch, useSelector } from 'react-redux';
import { TableUploadViewStyles as Styled } from "./uploadingView.style";
import { Ikuj_s3Event, Is3Event } from "screens/Inbox/types/inbox.types";

// import { TableInboxAdmin } from "components/Table/TableInboxAdmin";

// import { ReUseSearch } from "ReUseComponents/reUseSearch/ReUseSearch";
// import { ReUseDatePicker } from "ReUseComponents/reUseDatePicker/ReuseDatePicker";
// import { ReUseStatusFilter } from "ReUseComponents/reUseStatusFilter/ReUseStatusFilter";
// import { ReUseActionMenu } from "ReUseComponents/reUseActionMenu/ReUseActionMenu";
// import { ReUseActionButton } from "ReUseComponents/reUseActionButton/ReUseActionButton";

// import { EMPTY_DATA_STRINGS as Strings } from 'constants/strings';
// import { useClientSocketIO } from 'hooks/useSocketIO';

import { ReUseActionPlaceholder } from "ReUseComponents/reUseActionPlaceHolder/ReUseActionPlaceHolder";
import { StatusLabel } from "components/StatusLabel/StatusLabel";
import { Icon } from "components/Icons";
import { UploadViewModal } from "./UploadViewModal";
import { useUploadViewState } from "./useUploadView.state";
import { ReUseActionButton } from "ReUseComponents/reUseActionButton/ReUseActionButton";
import { IState } from "services/redux/reducer";

interface IUploadViewProps {
	status?: boolean;
	IconName?: string;
	uploadFileName?: string;
	uploadFileStatusMessage?: string;
	uploadFileStatus?: string;
	modalShow: boolean;
	modalToggle: () => void;
	onDeleteEventErrorHandler?: (jobid: string, key: string) => void;

	totalUploadss?: number | null;
	totalSuccesss?: number | null;
	totalPendingg?: number | null;
	totalRejected?: number | null;
	s3Status: Ikuj_s3Event[] | Is3Event[];
	// onCheckedItemHandler?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	// onCheckedPaidHandler: (
	//   event: React.ChangeEvent<HTMLInputElement>
	// ) => Promise<void>;
}

export const UploadingView: React.FC<IUploadViewProps> = (props) => {
	const { status, IconName, uploadFileName, uploadFileStatusMessage, uploadFileStatus, modalShow, modalToggle, s3Status, onDeleteEventErrorHandler } = props;

	const [totalUploads, setTotalUpload] = useState(s3Status?.length);
	const [totalSuccess, setTotalSuccess] = useState(0);
	const [totalError, setTotalError] = useState(0);
	const [totalPending, setTotalPending] = useState(s3Status?.length);

	const {
        // filesUpload: { previewFiles, filesArray, activeIndex },
        user: { token, 
			user: { id }
		},
        // inbox: { s3EventStatus },
        socket: { socketSingleTon }
    } = useSelector((state: IState) => state);

	const socketEmit = (jobid: string) => {
		if (socketSingleTon) {
			// Emit the 's3_ack' event
			console.log('time2',new Date().getTime());
			socketSingleTon.emit('s3_ack', { loginId: id, jobId: jobid }, (response: any) => {
				console.log('s3_ack -->', response);
			});
		} else {
			console.error('Socket is not connected, unable to emit event');
		}
	}

	useEffect(() => {
		setTotalUpload(s3Status?.length);
		const successStatus: any[] = [];
		s3Status.forEach((stat) => (stat.progress == 100 ? successStatus.push(true) : null));
		setTotalSuccess(successStatus.length);
		const errorStatus: any[] = [];
		s3Status.forEach((stat) => (stat.status == "error" ? errorStatus.push(true) : null));
		// s3Status.map((stat) => stat.status=='error');
		setTotalError(errorStatus.length);
		// console.log(successStatus);
		setTotalPending(totalUploads - (successStatus.length + errorStatus.length));
	}, [s3Status, totalError]);

	const { totalUploadss = 50, totalSuccesss = 40, totalPendingg = 9, totalRejected = 1 } = props;

	return (
		<>
			{false ? (
				<Styled.LoaderWrapper>
					<LoaderComponent theme="preview" />
				</Styled.LoaderWrapper>
			) : (
				<UploadViewModal isModalWindowOpen={modalShow} onCloseModalWindowHandler={modalToggle}>
					{/* <Styled.Wrapper> */}
					<ReUseActionPlaceholder>
						<Styled.Title>Status | Uploading Receipts.... </Styled.Title>
						<Styled.Stats>
							Total: {totalUploads} ( <Styled.Stat color={"green"}>Success: {totalSuccess}</Styled.Stat> | <Styled.Stat color={"mango"}>Pending: {totalPending}</Styled.Stat> | <Styled.Stat color={"red"}>Error: {totalError}</Styled.Stat> )
						</Styled.Stats>
						<Styled.Icon onClick={modalToggle}>
							<Icon type="closeBig" />
						</Styled.Icon>
					</ReUseActionPlaceholder>
					<Styled.TableWrapper>
						<>
							<Styled.Head>
								{/* <Styled.Text>Icon</Styled.Text> */}
								<Styled.Text>File Name</Styled.Text>
								{/* <Styled.Text>Status Message</Styled.Text> */}
								<Styled.Text>Status</Styled.Text>
								<Styled.Text>Progress / Action</Styled.Text>
							</Styled.Head>

							{s3Status?.map((s3, i) => {
								if (s3?.message == "Provisioned rate exceeded" && socketSingleTon?.connected) { 
									// socketEmit(s3?.job_id);
									// console.log('time2',new Date().getTime());
									socketSingleTon.emit('s3_ack', { loginId: id, jobId: s3?.job_id }, (response: any) => {
									console.log('s3_ack again -->', response);
								});
								} else {
									console.log('retry fail');
								}
								return (
									<Styled.Item key={i}>
										<Styled.Selector title={s3?.filename}>
											<Styled.ValueWrapper>{s3?.filename || "-----"}</Styled.ValueWrapper>
										</Styled.Selector>
										<Styled.Status title={s3?.message}>
											<StatusLabel status={s3?.status == "error" ? "error" : s3?.progress == 100 ? "success" : s3?.progress == 0 ? "pending" : ("processing" as Statuses)} />
										</Styled.Status>
										<Styled.Status>
											{s3?.status == "error" && s3?.message !== "Provisioned rate exceeded" ? (
												<>
													<ReUseActionButton
														displayText="Retry"
														/* buttonType="text-link" */ buttonType="actionButton"
														widthType="primary"
														themedButton="primary"
														customHeight="30px"
														customwidth="60%"
														onClick={() => {
															console.log("link button");
														}} /* customColor={`${theme.colors.red}`} fontSize="22px" */
													/>
													|
													<Styled.IconWrapper onClick={() => onDeleteEventErrorHandler && onDeleteEventErrorHandler(s3?.job_id, s3?.key || "")}>
														<Icon type="deleteAccount" />
													</Styled.IconWrapper>
												</>
											) : (
												<Styled.Bar>
													<Styled.Progress width={s3?.progress}></Styled.Progress>
													{/* <Styled.Progress width={100 - ((100 * totalPending) / totalUploads)}></Styled.Progress> */}
												</Styled.Bar>
											)}
										</Styled.Status>
									</Styled.Item>
								);
							})}
						</>
					</Styled.TableWrapper>
					{/* </Styled.Wrapper> */}
				</UploadViewModal>
			)}
		</>
	);
};

{
	/* <Styled.Selector>
                                            <Styled.ValueWrapper>{IconName && <Icon type={IconName || 'addPlus'} />}</Styled.ValueWrapper>
                                        </Styled.Selector> */
}

{
	/* <Styled.Selector title={s3?.message}>
											<Styled.ValueWrapper>{s3?.message || "-----"}</Styled.ValueWrapper>
										</Styled.Selector> */
}
