import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Axios from 'axios';
import { CONFIG } from 'constants/config';
import { apiServices } from 'services/api-service';

import { IState } from 'services/redux/reducer';
import { useOutsideClick } from 'hooks/useOutsideClick';

import { setIsFetchingData, setReceipts, setS3Presigned, setS3PendingStatus } from '../../screens/Inbox/reducer';
// import { receiptCreate, salesCreate, s3Create, s3Upload, getUserID } from './filesUploadPreview.api';

// import { useUploadAvatar } from 'hooks/useUploadAvatar';
// import { useLogout } from 'hooks/useLogout';
// import { useGetCompanyLogo } from 'hooks/useGetCompanyLogo';
// import {
//   setCompanySwitcher,
//   setIsSwitchCompany,
// } from 'screens/Settings/reducer/settings.reducer';
// import { switchAccount } from 'screens/SignUp/reducer/signup.reducer';
// import { getUserCompanies, selectActiveAccount } from './header.api';
// import { getAvatarLinks } from 'constants/header-links';
// import { setIsCompanyChanged } from '../../screens/Inbox/reducer';

export const useParallelUpload = (filesArray: File[]) => {
    console.log(filesArray);
    const dispatch = useDispatch();
    const {
        // filesUpload: { previewFiles, filesArray, activeIndex },
        user: { token, user },
        // inbox: { s3EventStatus },
        socket: { socketSingleTon }
    } = useSelector((state: IState) => state);

    const uploadPure = async () => {
        try {
            const filename = filesArray.map((file) => file.name);
            const key_url_jobid = await s3Create({ action: 'receipt', files: filename }, token);
            const uploadPermit = key_url_jobid.map((upload: { key: string, url: string, job_id: string }, index: number) => {
                const nameoffile = key_url_jobid[index].key.substring(key_url_jobid[index].key.lastIndexOf('/') + 1);
                return dispatch(setS3PendingStatus({ status: 'uploading...', message: 'In processing Queue', progress: 0, job_id: key_url_jobid[index].job_id, filename: nameoffile, pureFile: filesArray[index], key: upload.key, url: upload.url }));
            });

            const loginID = await getUserID();
            // console.log(loginID);
            dispatch(setS3Presigned(key_url_jobid));
            dispatch(setIsFetchingData(true));
            console.log(key_url_jobid);

            // const uploadBinaryStatus = await Promise.all(key_url_jobid.map(async (kuj: { key: string, url: string, job_id: string }, index: number) => {
            //     const binaryFileData = await hitapiwithbinary(filesArray[index]); //convert image to binary
            //     // console.log('binaryFileData');
            //     const oneUpload = await s3Upload(kuj.url, binaryFileData, filesArray[index]);
            //     if (oneUpload) {
            //         if (socketSingleTon) {
            //             // Emit the 's3_ack' event
            //             socketSingleTon.emit('s3_ack', { loginId: loginID?.data?.user?.id, jobId: key_url_jobid[index].job_id }, (response: any) => {
            //                 console.log('s3_ack -->', response);
            //             }
            //             );
            //         } else {
            //             console.error('Socket is not connected, unable to emit event');
            //         }
            //     }
            //     return oneUpload;
            // }));
            // console.log(await uploadBinaryStatus, typeof uploadBinaryStatus, (await uploadBinaryStatus).length);

            const arr200 = [];
            for (let a = 0; a < key_url_jobid.length; a++) {
                const binaryFileData = await hitapiwithbinary(filesArray[a]); //convert image to binary
                const oneUpload = await s3Upload(key_url_jobid[a].url, binaryFileData, filesArray[a]);
                const nowDate = new Date().getTime();
                console.log('time1',new Date().getTime());
                arr200.push({oneUpload, nowDate});

                if (oneUpload) {
                    if (socketSingleTon) {
                        // Emit the 's3_ack' event
                        console.log('time2',new Date().getTime());
                        socketSingleTon.emit('s3_ack', { loginId: loginID?.data?.user?.id, jobId: key_url_jobid[a].job_id }, (response: any) => {
                            console.log('s3_ack -->', response);
                        }
                        );
                    } else {
                        console.error('Socket is not connected, unable to emit event');
                    }
                }
                console.log(arr200);

                //   const nameoffile = key_url_jobid[a].key.substring(key_url_jobid[a].key.lastIndexOf('/') + 1);
                //   dispatch(setS3PendingStatus({status: oneUpload == 200 ? 'pending' : 'not Upload', message: oneUpload == 200 ? 'In processing Queue' : 'Upload again', progress: 0, job_id: key_url_jobid[a].job_id, filename: nameoffile}));
                }

                // setIsLoading(false);

            } catch (error) {
                dispatch(setIsFetchingData(false));
                console.log(error);
            }
        }

    return { uploadPure };
    }

    export const s3Create = async (payload: { action: string, files: string[] }, token: string) => {
        const URL = `${CONFIG.apiUrl}aws/presiged`;
        console.warn('APIIIIIIIII', URL, '---', payload);
        return Axios.put(URL, payload, {
            headers: {
                // 'content-type': `multipart/form-data`,
                'content-type': `application/json`,
                Authorization: `Bearer ${token}`,
            },
        }).then(response => {
            console.log(response.data); // Access the response data here
            if (response.data) {
                return response.data;
            }
        })
            .catch(error => {
                console.error(error);
            });
    };

    export const getUserID = async () => {
        const URL = `${CONFIG.apiUrl}profile/get`
        return await apiServices.fetchData(`${CONFIG.apiUrl}profile/get`);
        return Axios.get(URL).then(response => {
            console.log(response); // Access the response data here
            if (response) {
                return response.data;
            }
        })
            .catch(error => {
                console.error(error);
            });
    }

    export const s3Upload = (url: string, payload: string | ArrayBuffer | null, file: File) => {
        const URL = url;
        console.warn('APIIIIIIIII', URL, '---', payload);
        return Axios.put(URL, payload, {
            headers: {
                'Content-Type': file.type || 'image/jpeg',
                'Content-Disposition': `attachment; filename="${file.name}"`,
            }
        }).then(response => {
            console.log(response); // Access the response data here
            if (response.status) {
                return response.status;
            }
        })
            .catch(error => {
                console.error(error);
            });;
    }

    const hitapiwithbinary = (file: File): Promise<ArrayBuffer | null | string> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onloadend = function (e) {
                resolve(reader.result);
            };

            reader.onerror = function () {
                reject(new Error('Failed to read file'));
            };
        });
    }