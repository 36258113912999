export const PAYMENT_INITIAL_STATE = {
    isEdit: false,
    searchValue: '',
    modalInputPaymentName: '',
    modalInputPaymentRefName: '',
    modalCheckboxDefaultPayment: false,
    prevInputValue: '',
    isLoading: false,
    isEmptyData: false,
    isFetchingData: true,
    isFocus: false,
    isHeaderPanel: false,
    isSearching: false,
    searchedItems: [],
    isContentLoading: false,
    setMasterCurrentActionItem: null,
  };

  export interface IpaymentInitialState {
    isEdit: boolean,
    searchValue: string,
    modalInputPaymentName: string,
    modalInputPaymentRefName: string,
    modalCheckboxDefaultPayment: boolean,
    prevInputValue: string,
    isLoading: boolean,
    isEmptyData: boolean,
    isFetchingData: boolean,
    isFocus: boolean,
    isHeaderPanel: boolean,
    isSearching: boolean,
    searchedItems: any[],
    isContentLoading: boolean,
    setMasterCurrentActionItem: any[] | null,
  };